.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root{
  height: 100vh;
}

/* size of map */

.leaflet-container{
  height: 100vh;
}

.zoomButton {
  position: absolute;
  top: 60px;
  right: 11px;
  padding: 5px; 
  z-index: 400;
  background-color: white;
  width: 46px;
  height: 40px;
  cursor: pointer;
  
}

.map-container {
  height: 100%;
  width: 100%;
}

/* .App {
  height: 90vh;
  width: 100%;
} */


.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 29px;
  height: '100vh';
  background-image: url('../src/images/LoginWallpaper1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ant-collapse-content-box {
  background: #F5F5F5;
}

.graphZoomButton {
  position: absolute;
  top: -30px;
  right: -30px;
  padding: 5px; 
  z-index: 400;
  background-color: white;
  width: 36px;
  height: 30px;
  /* left: 0px; */
  cursor: pointer;
  border: #F5F5F5;

}


.graphZoomOutButton {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px; 
  z-index: 400;
  background-color: white;
  width: 36px;
  height: 30px;
  left: 0px;
  cursor: pointer;
  border: #F5F5F5;
}

.wellInfo {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px; 
  z-index: 400;
  background-color: white;
  width: 150px;
  /* height: 80px; */
  left: 10px;
  cursor: pointer;
  /* border: 1px solid black; */

}


.graphCloseButton {
  position: absolute;
  top: -35px;
  right: -30px;
  padding: 5px; 
  z-index: 400;
  background-color: white;
  width: 36px;
  height: 30px;
  /* left: 0px; */
  cursor: pointer;
  border: #F5F5F5;
}


.card-full-width {
  width: 100%;
}