.heading-alarmcount-label {
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .ant-table-row-level-0 > .ant-table-cell {
    padding-top: 0;
    padding-bottom: 0;
  }